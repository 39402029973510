import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'

class Terms extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="content terms dark-bg">
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-8 offset-md-2'>
                            <Link className="btn back" to="/"><FaArrowLeft />  Home</Link>
                            <br />
                            <br />
                            <p>Last edited 20/02/22</p>
                            <h2>Terms & Conditions</h2>
                            <h4>Test </h4>
                            <p>Test paragraph</p>
                            <h4>Test </h4>
                            <p>Test paragraph</p>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Terms;
