import React, { Component } from 'react'


class NotConnected extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


  
    render() {
        return (
            <div className="NotConnected">
                not connected
            </div>
        );
    }
}

export default NotConnected;
