import React, { Component } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Foot from './Components/foot';
import Home from './Components/home';
import Landing from './Components/landing';
import Mint from './Components/mint';
import Terms from './Components/terms';
import Connect from './Components/connect';
import { ethers } from "ethers";
import { withSnackbar } from 'notistack';
import Rollout from './Components/rollout';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      defaultAccount: null,
      provider: null,
      balance: null, 
      auth: false
    };
    this.connectWalletHandler = this.connectWalletHandler.bind(this);
  }



  componentDidMount() {


    const queryParams = new URLSearchParams(window.location.search)
    const pass = queryParams.get("pass")
    if(pass && pass === 'spaceclubcrew3901'){
      this.setState({ auth: true }) 
    }
    

    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum.on('accountsChanged', () => this.getAccount(true));
      window.ethereum.on('chainChanged', () => window.location.reload());
      window.ethereum.on('connect', () => this.updateAccountState(window.ethereum.selectedAddress));
    }
  }

  //wallet
  connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask && this.state.defaultAccount == null) {
      this.setState({ provider: new ethers.providers.Web3Provider(window.ethereum) }) // set ethers provider
      this.getAccount();

    } else if (!window.ethereum) {
      this.props.enqueueSnackbar("Please install MetaMask to interact", { variant: 'error' });
    }
  }

  getAccount(isUpdate = false) {
    window.ethereum.request({ method: 'eth_requestAccounts' })
      .then(result => {
        this.updateAccountState(result[0]);
        this.updateBalanceState(result[0]);
        this.props.enqueueSnackbar(isUpdate ? "Wallet Updated" : "Wallet Connected", { variant: 'success' });

      })
      .catch(error => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      });
  }

  updateAccountState(newAccount) {
    debugger;
    this.setState({ defaultAccount: newAccount });
  }

  updateBalanceState(account) {
    this.state.provider.getBalance(account)
      .then(result => {
        this.setState({ balance: result });
      })
      .catch(error => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      });
  }

  render() {
    return (
      <Router>
        <section className="App">


          {this.state.auth ?  <Connect connectWalletHandler={this.connectWalletHandler} defaultAccount={this.state.defaultAccount} /> : null}
         
          <Routes>

            {this.state.auth ? <Route exact path='/' element={<Home />}></Route> :  <Route exact path='/' element={<Landing />}></Route>}
            
            <Route exact path='/mint'
              element={
                <Mint
                  connectWalletHandler={this.connectWalletHandler}
                  defaultAccount={this.state.defaultAccount}
                  balance={this.state.balance}
                />
              }>
            </Route>

            <Route exact path='/terms' element={<Terms />}></Route>
            <Route exact path='/rollout' element={<Rollout />}></Route>
          </Routes>
          {this.state.auth ?  <Foot /> : null}

        </section>
      </Router>

    );
  }
}

export default withSnackbar(App);
