import React, { Component } from 'react'
import { FaTwitter, FaDiscord } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
// import {IoShareSocial} from 'react-icons/io5';

class Connect extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    truncate(str) {
        console.log(str);
        return str.length > 10 ? str.substring(0, 5) + "..." + str.substring(str.length - 5, str.length) : str;
    }

    render() {
        return (
            <div id="connectWallet">
                <div className="container" >
                    <div className="row">

                        {/* Social Icons */}
                        <div className="col d-flex flex-md-column align-items-start">
                            {/* eslint-disable-next-line */}
                            <a href="https://instagram.com/spaceclub_nft" target="_blank" className='me-1'><AiFillInstagram size="2rem" /></a>
                            {/* eslint-disable-next-line */}
                            <a href="https://twitter.com/TheSpaceClubNft" target="_blank" className='me-1'><FaTwitter size="2rem" /></a>
                            {/* eslint-disable-next-line */}
                            <a href="https://discord.gg/mhDH2QJRXb" target="_blank" className='me-1'><FaDiscord size="2rem" /></a>
                        </div>


                        <div className="col text-end d-none d-md-block">
                            {/* Connect btn */}
                            <button className="btn" onClick={this.props.connectWalletHandler}>
                                <span className={this.props.defaultAccount ? "connected" : ""}></span>
                                {this.props.defaultAccount ? this.truncate(this.props.defaultAccount) : "Connect"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Connect;
