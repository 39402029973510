import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import tv from '../asstes/example-assets/tv-crp2.png';
import tvGif from '../asstes/example-assets/tv-gif.gif';
import tvRight from '../asstes/general/tv-right.png';
import tvLeft from '../asstes/general/tv-left.png';
import dotSm from '../asstes/general/dot-sm.png';
import dotLg from '../asstes/general/dot-lg.png';

class About extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="about dark-bg" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-5 offset-lg-1 my-auto d-flex flex-column justify-content-end position-relative">
                            <img className="dot sm" alt="" src={dotSm} />
                            <img className="dot md" alt="" src={dotLg} />
                            <img className="dot md two" alt="" src={dotLg} />
                            <img className="dot lg" alt="" src={dotLg} />
                            <img className="dot lg two" alt="" src={dotLg} />
                            <h1>Welcome To <br /> The <span className="emphasis">Space Club</span></h1>
                            <p>
                               Home to <b>10,000</b> NFTs floating through the <b>Ethereum</b> universe. Who are these special individuals and why should I care? Because this crew has faced the perils of space itself and more.

                            </p>
                            <p>Join the club today and explore the known multiverse of unique species!</p>
                        
                        </div>
                        <div className="col-12 col-lg-4 offset-lg-1 my-lg-auto ">
                            <div className="image text-center text-md-left mx-auto">
                                <img className="gif" alt="" src={tvGif} />
                                <img alt="" src={tv} />
                                <img className="left" alt="" src={tvLeft} />
                                <img className="right" alt="" src={tvRight} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default About;
