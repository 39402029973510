import React, { Component } from 'react'
import Head from "./head";
import About from "./about";
import Assets from './assets';
import Team from "./team";
import Gallery from "./gallery";
import Roadmap from "./roadmap";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <section className="Home">
                <Head />
                <About/>
                <Assets />
                <Team />
                <Roadmap />
                <Gallery />

            </section>
        );
    }
}
export default Home;
