import React, { Component } from 'react'
import Slider from "./slider";


class Gallery extends Component {
    render() {
        return (
            <div className="gallery">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-10 offset-md-1">
                            <h2>Gallery</h2>
                        </div>

                    </div>
                </div>
                    <Slider />
            </div>
        );
    }
}

export default Gallery;
