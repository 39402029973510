import React, { Component } from 'react'
import Game from './game';
import NotConnected from './notconnected';


class Mint extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    //on render ?


    render() {
        return (
            <div className="mint">
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-10 offset-md-1'>
                            {this.props.defaultAccount ? <Game /> : <NotConnected />}
                        </div></div>
                </div>
            </div>
        );
    }
}

export default Mint;
