import React, { Component } from 'react'
import { FiCheck, FiX } from 'react-icons/fi';
import { FaTwitter } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';


import team1 from '../asstes/team/team1.png';
import team2 from '../asstes/team/team2.png';
import team3 from '../asstes/team/team3.png';

import arrow from '../asstes/general/arrow.png';

class Team extends Component {


    render() {
        return (
            <div className="team position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-10 offset-lg-1">
                            <h2>The Team</h2>
                            <img className="arrow" alt="" src={arrow} />
                        </div>

                        <div className="col-12 col-lg-10 offset-lg-1 d-flex flex-wrap justify-content-around justify-content-lg-space-between">
                        <a href="https://twitter.com/dferendo"  rel="noreferrer" className="team-member" target="_blank">
                                <div className="image">
                                    <img alt="" src={team2} />
                                </div>
                                <h4>0xPain</h4>
                                <h5 className='small'>Solidity Engineer</h5>
                                <div className="details small">
                                    <h5><FiCheck />Smartest guy we know</h5>
                                    <h5><FiX />Has no game</h5>
                                    <h5 className='social'><FaTwitter />@dferendo</h5>
                                </div>
                            </a>
                            <a href="https://www.instagram.com/onvver"  rel="noreferrer" className="team-member" target="_blank">
                                <div className="image">
                                    <img alt="" src={team3} />
                                </div>
                                <h4>Onvver</h4>
                                <h5 className='small'>Artist</h5>
                                <div className="details">
                                    <h5><FiCheck />Makes pretty things</h5>
                                    <h5><FiX />Puts milk before cereal</h5>
                                    <h5 className='social'><AiFillInstagram />@onvver</h5>

                                </div>
                            </a>

                            <a href="https://twitter.com/M_Hempton"  rel="noreferrer" className="team-member" target="_blank">
                                <div className="image">
                                    <img alt="" src={team1} />
                                </div>
                                <h4>Hemp</h4>
                                <h5 className='small'>Frontend</h5>
                                <div className="details">
                                    <h5><FiCheck />Clueless</h5>
                                    <h5><FiX />Clueless</h5>
                                    <h5 className='social'><FaTwitter />@M_Hempton</h5>

                                </div>

                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Team;
