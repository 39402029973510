import React, { Component } from 'react'


class Game extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
  

   
    render() {
        return (
            <div className="Game">
                game
            </div>
        );
    }
}

export default Game;
