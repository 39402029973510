import React, { Component } from "react";
import Slider from "react-slick";
import { FiChevronLeft } from 'react-icons/fi';
import { FiChevronRight } from 'react-icons/fi';

import gallery1 from '../asstes/gallery-assets/gallery (1).png';
import gallery2 from '../asstes/gallery-assets/gallery (2).png';
import gallery3 from '../asstes/gallery-assets/gallery (3).png';
import gallery4 from '../asstes/gallery-assets/gallery (4).png';
import gallery5 from '../asstes/gallery-assets/gallery (5).png';
import gallery6 from '../asstes/gallery-assets/gallery (6).png';
import gallery7 from '../asstes/gallery-assets/gallery (7).png';
import gallery8 from '../asstes/gallery-assets/gallery (8).png';
import gallery9 from '../asstes/gallery-assets/gallery (9).png';
import gallery10 from '../asstes/gallery-assets/gallery (10).png';
import gallery11 from '../asstes/gallery-assets/gallery (11).png';
import gallery12 from '../asstes/gallery-assets/gallery (12).png';
import gallery13 from '../asstes/gallery-assets/gallery (13).png';
import gallery14 from '../asstes/gallery-assets/gallery (14).png';
import gallery15 from '../asstes/gallery-assets/gallery (15).png';
import gallery16 from '../asstes/gallery-assets/gallery (16).png';
import gallery17 from '../asstes/gallery-assets/gallery (17).png';
import gallery18 from '../asstes/gallery-assets/gallery (18).png';
import gallery19 from '../asstes/gallery-assets/gallery (19).png';
import gallery20 from '../asstes/gallery-assets/gallery (20).png';
import gallery21 from '../asstes/gallery-assets/gallery (21).png';

export default class SimpleSlider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    prev() {
        this.slider.slickPrev();
    }

    render() {


        //get images
        const imgSrcs = [  gallery3, gallery4, gallery1,gallery5, gallery2, gallery6,  gallery8, gallery9, gallery10, gallery7,gallery11, gallery12, gallery13, gallery14, gallery15, gallery16, gallery17, gallery18, gallery19, gallery20, gallery21,]

        const imgList = imgSrcs.map((imgSrcs, index) =>
            <img key={index} alt="" src={imgSrcs} />
        );

        //slider settings
        var settings = {
            dots: false,
            infinite: true,
            centerMode: true,
            speed: 300,
            slidesToShow: imgSrcs.length > 3 ? 3 : imgSrcs.length,
            slidesToScroll: 1,
            arrows: false,
            lazyLoad: 'ondemand',
            autoplay: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <div className="slider-wrapper">
                            <Slider ref={c => (this.slider = c)} {...settings} className="slider">
                                {imgList}
                            </Slider>
                            <div className="slider-buttons d-sm-none d-lg-flex">
                                <span onClick={this.prev}><FiChevronLeft size="2rem" title="Previous" /></span>
                                <span onClick={this.next}><FiChevronRight size="2rem" title="Next" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}