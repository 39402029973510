import React, { Component } from 'react';
import RellaxWrapper from 'react-rellax-wrapper';
import AOS from 'aos';
import 'aos/dist/aos.css';

//assets
import spaceship from '../asstes/bg-assets/ship.png';
import alien from '../asstes/bg-assets/alien.png';
import apple from '../asstes/bg-assets/apple.png';
import dismembered from '../asstes/bg-assets/dismembered.png';
import monkey from '../asstes/bg-assets/monkey.png';
import robot from '../asstes/bg-assets/robot.png';
import skull from '../asstes/bg-assets/skull.png';
import suit from '../asstes/bg-assets/suit.png';
import paper from '../asstes/bg-assets/paper.png';

AOS.init();

class Head extends Component {
    render() {
        return (
            <div className="head">
                <div className="container-fluid">
                    <div className="container">
                        <div className='row'>
                            <div className='col position-relative'>
                                <div id="title" className="dark-bg" data-aos="fade" data-aos-delay="500" data-aos-once="false" data-aos-anchor-placement="bottom">
                                    <img alt="" src={paper} />
                                    <h1>The Space Club</h1>
                                </div></div></div></div>

                    <div id="ship" className="head-assets"  >
                        <RellaxWrapper speed={1.5}  >
                            <img src={spaceship} className="rellax" alt="spaceship" />
                        </RellaxWrapper>
                    </div>
                    <div id="alien" className="head-assets" >
                        <RellaxWrapper speed={0.4}  >
                            <img src={alien} className="rellax" alt="alien" />
                        </RellaxWrapper>
                    </div>
                    <div id="apple" className="head-assets d-none d-md-block" >
                        <RellaxWrapper speed={0.4}  >
                            <img src={apple} className="rellax" alt="apple" />
                        </RellaxWrapper>
                    </div>
                    <div id="dismembered" className="head-assets d-none d-md-block" >
                        <RellaxWrapper speed={0.3}  >
                            <img src={dismembered} className="rellax" alt="dismembered" />
                        </RellaxWrapper>
                    </div>
                    <div id="monkey" className="head-assets" >
                        <RellaxWrapper speed={0.5}  >
                            <img src={monkey} className="rellax" alt="monkey" />
                        </RellaxWrapper>
                    </div>
                    <div id="robot" className="head-assets d-none d-md-block" >
                        <RellaxWrapper speed={0.5}  >
                            <img src={robot} className="rellax" alt="robot" />
                        </RellaxWrapper>
                    </div>
                    <div id="skull" className="head-assets d-none d-md-block" >
                        <RellaxWrapper speed={0.3}  >
                            <img src={skull} className="rellax" alt="skull" />
                        </RellaxWrapper>
                    </div>
                    <div id="suit" className="head-assets d-none d-md-block" >
                        <RellaxWrapper speed={0.5}  >
                            <img src={suit} className="rellax" alt="suit" />
                        </RellaxWrapper>
                    </div>

                    <div className='icon-scroll'></div>
                </div>
            </div>
        );
    }
}

export default Head;
