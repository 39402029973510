import React, { Component } from 'react'
import monkeySq from '../asstes/example-assets/ape.png'
import background1 from '../asstes/example-assets/bg.png'
import eyes from '../asstes/example-assets/eyes.png'
import mouth from '../asstes/example-assets/mouth.png'
import suit from '../asstes/example-assets/suit.png'
import onHelmet from '../asstes/example-assets/headmount.png'
import inHelmet from '../asstes/example-assets/helmet.png'
import headgear from '../asstes/example-assets/headgear.png'
import tv from '../asstes/example-assets/tv-crp.png'
import tvStatic from '../asstes/example-assets/static.gif'
import planet from '../asstes/general/species-min.gif'
import bgText1 from '../asstes/general/text-bg-1.png'
import bgText2 from '../asstes/general/text-bg-2.png'


class Assets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHover: false,

            // assets
            base: true,
            background: true,
            eyes: true,
            mouth: true,
            headgear: true,
            onHelmet: true,
            suit: true,
            inHelmet: true,
        };
    }

    handleAssetHover(name, value) {
        this.setState({ isHover: value });
        this.setState({ [name]: !value });

    }
    render() {
        return (
            <div className="characteristics dark-bg">
                <div className="characters">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-5 offset-lg-1 order-lg-1">
                                <div className="sub-title">
                                    <img src={bgText1} alt="" />
                                    <h2>Species</h2>
                                </div>
                               

                                <p>
                                    The crew is composed of <b>four main species</b> Your stowaway <b>ape</b>, cunning <b>alien</b>, mathematically challenged <b>robot</b> and lively <b>skeleton</b>.
                                    Take note, you avid space enjoyer that these species may come in <b>different shapes and colour</b>..while others may come with much <b>more exuberant changes</b> (1/1 specials)
                               
                                </p>

                            </div>
                            <div className="col-12 col-lg-4 offset-lg-1 order-lg-0 m-auto planet">
                                <img src={planet} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="assets">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 offset-lg-1 order-lg-1 ">
                                <div className="sub-title mobile">
                                    <img src={bgText2} alt="" />
                                    <h2>traits</h2>

                                </div>
                                <div className="example-assets">

                                    <img alt="" src={background1} className={this.state.isHover && this.state.background ? "background blured" : ""} />
                                    <img alt="" src={suit} className={this.state.isHover && this.state.suit ? "blured" : ""} />
                                    <img alt="" src={monkeySq} className={this.state.isHover && this.state.base ? "blured" : ""} />
                                    <img alt="" src={mouth} className={this.state.isHover && this.state.mouth ? "blured" : ""} />
                                    <img alt="" src={eyes} className={this.state.isHover && this.state.eyes ? "blured" : ""} />
                                    <img alt="" src={headgear} className={this.state.isHover && this.state.headgear ? "blured" : ""} />
                                    <img alt="" src={inHelmet} className={this.state.isHover && this.state.inHelmet ? "blured" : ""} />
                                    <img alt="" src={onHelmet} className={this.state.isHover && this.state.onHelmet ? "blured" : ""} />
                                    <img alt="" src={tvStatic} className="static" />
                                    <img alt="" src={tv} className="tv" />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 offset-lg-1 order-lg-0 my-lg-auto">
                                <div className="sub-title desktop">
                                    <img src={bgText2} alt="" />
                                    <h2>traits</h2>
                                </div>
                                <p>Each NFT is <b>randomly generated</b> from a combination of <b>144 distinct traits</b> of varying rarity, designed by our artist extraordinaire.</p>

                                <ul>
                                    <li onMouseEnter={() => this.handleAssetHover("background", true)} onMouseLeave={() => this.handleAssetHover("background", false)}><h3>Background</h3></li>
                                    <li onMouseEnter={() => this.handleAssetHover("base", true)} onMouseLeave={() => this.handleAssetHover("base", false)}><h3>Character</h3></li>
                                    <li onMouseEnter={() => this.handleAssetHover("eyes", true)} onMouseLeave={() => this.handleAssetHover("eyes", false)}><h3>Eyes</h3></li>
                                    <li onMouseEnter={() => this.handleAssetHover("mouth", true)} onMouseLeave={() => this.handleAssetHover("mouth", false)}><h3>Mouth</h3></li>
                                    <li onMouseEnter={() => this.handleAssetHover("suit", true)} onMouseLeave={() => this.handleAssetHover("suit", false)}><h3>Suit</h3></li>
                                    <li onMouseEnter={() => this.handleAssetHover("inHelmet", true)} onMouseLeave={() => this.handleAssetHover("inHelmet", false)}><h3>In Helmet</h3></li>
                                    <li onMouseEnter={() => this.handleAssetHover("headgear", true)} onMouseLeave={() => this.handleAssetHover("headgear", false)}><h3>Headgear</h3></li>
                                    <li onMouseEnter={() => this.handleAssetHover("onHelmet", true)} onMouseLeave={() => this.handleAssetHover("onHelmet", false)}><h3>On Helmet</h3></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Assets;
