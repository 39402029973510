import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { FaTwitter } from 'react-icons/fa';
import { FaDiscord } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';


class Foot extends Component {

    render() {
        return (
            <section className="foot dark-bg">
                <div className="container">
                    <div className="row">
                        <div className="col socials">
                            {/* eslint-disable-next-line */}
                            <a href="https://instagram.com/spaceclub_nft" target="_blank"><AiFillInstagram size="1.5rem" /></a>
                            {/* eslint-disable-next-line */}
                            <a href="https://twitter.com/TheSpaceClubNft" target="_blank"><FaTwitter size="1.5rem" /></a>
                            {/* eslint-disable-next-line */}
                            <a href="https://discord.gg/mhDH2QJRXb" target="_blank"><FaDiscord size="1.5rem" /></a>
                        </div>

                        <div className="col text-end links">
                        <p> 
                          <Link to="/rollout">Rollout</Link>
                            <Link to="/terms">Terms &amp; Conditions</Link>
                             {/* <span className='emphasis'>Space Club Crew - twenny twenny two</span> </p> */}
                             <span className='emphasis'>Space Club Crew</span> </p>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Foot;
