import React, { Component } from 'react'
import { FiCheck } from 'react-icons/fi';
import roadmap from '../asstes/general/roadmap.png';

class Roadmap extends Component {
    render() {
        return (
            <div className="roadmap">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-4 col-xl-3 offset-lg-1 my-auto">
                            <h2>Roadmap</h2>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-7 ">
                        <img className="arrows" alt="" src={roadmap} />

                        </div>
                        <div className="col-12 col-lg-10 offset-lg-1">
                            <div className="list">
                                <div className="item active"><h5>0%</h5><p>Launch website & begin our journey</p><i><FiCheck /></i></div>
                                <div className="item"><h5>33%</h5><p>Pay back the loan from our girlfriends boyfriends</p><i><FiCheck /></i></div>
                                <div className="item"><h5>66%</h5><p>Buy the top & cry</p><i><FiCheck /></i></div>
                                <div className="item"><h5>100%</h5><p>Commission a great artist to pay tribute to HAM the chimp, the first ever ape in space</p><i><FiCheck /></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Roadmap;
