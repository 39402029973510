import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'

class Rollout extends Component {

    render() {
        return (
            <div className="content rollout dark-bg">
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-8 offset-md-2'>
                            <div className='btn-wrapper'>
                                <Link className="btn back" to="/"><FaArrowLeft />  Home</Link>
                            </div>
                            <br />
                            <br />
                            <h2>The Roll out</h2>

                            <p>The <span className='emphasis'>space club crew</span> believe in transparent and fair distribution to the community. Details of the rollout can be found below. For further questions contact us through one of our social channels.</p>

                            <ul>
                                <li><p> Space Club NFTs can be minted for the price of <b>0.069 eth</b> per NFT</p></li>

                                <li><p> A total of <b>10,000</b> Space Club NFTs will be created. The crew aswell as collaborators and influencers may receive a custom NFT.</p></li>
                                
                                <li><p>There will be <b>no whitelist or presale mints</b> open to non-crew members, Space club NFTs can only be minted on release giving every potential owner an equal opportunity. </p></li>
                                
                                <li><p> A reserve of <b>150</b> NFTs will be minted by the crew prior to launch for pre and post launch marketing which include givaways and gifts. A portion of the reserved NFTs will be shared amongst the crew for distribution as they see fit.</p></li>

                                <li><p>The <b>reveal</b> of the NFTs will occur 3 days following the launch of the mint </p></li>

                                <li><p> Minting can be done through this website on the day of launch.</p></li>

                                <li><p> As a method of <b>bot deterance</b>, minters will be asked to complete a short task before being allowed to mint.</p></li>

                                <li><p> Each wallet will only be allowed to mint a <b>limited</b> number of Space Club NFTs</p></li>

                                <li><p> A royalty fee of <b>5%</b> is taken from sales following the mint</p></li>

                                <li><p> Asset Details: <br />
                                    Format:<b></b><br />
                                    Dimensions:<b></b><br />
                                </p></li>

                                <li><p>Our <a href="#">Smart Contract</a> uses ERC721A for GAS efficiency</p></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Rollout;
